let $loadMore;
let $loadMoreItems;
let $loadMoreTrigger;

function cacheDOM() {
  $loadMore = document.querySelector('.js-load-more');
}

function updatePortfolioItems(data, page, maxPages) {
  setTimeout(() => {
    $loadMoreItems.insertAdjacentHTML('beforeend', data );
    // Update current page attribute on load more button
    $loadMoreTrigger.setAttribute('data-current-page', page);
  }, 500);

  // Hide load more button if we reached the last page
  if(page >= maxPages) {
    $loadMoreTrigger.style.opacity = 0;
    setTimeout(() => { $loadMoreTrigger.style.display = 'none'; }, 500);
  }
}

function loadMorePosts(loadMore) {
  $loadMoreTrigger = loadMore.querySelector('.js-load-more-trigger');
  $loadMoreItems = loadMore.querySelector('.js-load-more__items');

  if($loadMoreTrigger) {
    $loadMoreTrigger.addEventListener('click', function() {
      const metaQueryKey = this.getAttribute('data-meta-query-key');
      const metaQueryValue = this.getAttribute('data-meta-query-value');
      const postsReturn = this.getAttribute('data-posts-return');
      let currPage = this.getAttribute('data-current-page');
      const maxPages = this.getAttribute('data-max-pages');
      const postType = this.getAttribute('data-post-type');
  
      currPage++;
  
      // Fetch all posts if none is active
      fetch(load_more_ajax_params.ajaxurl + '?action=load_more&post_type=' + postType + '&meta_query_value=' + metaQueryValue + '&meta_query_key=' + metaQueryKey + '&posts_return=' + postsReturn + '&current_page=' + currPage + '&max_pages=' + maxPages)
        .then(response => {
          if(!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
          }
          return response.text();
        })
        .then(data => {
            updatePortfolioItems(data, currPage, maxPages);
        }).catch(error => {
            console.error('Error:', error);
        });
    });
  }
}

export default function init() {
  cacheDOM();

  if($loadMore) {
    loadMorePosts($loadMore);
  }
}

init();